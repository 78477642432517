<script setup>
const prop = defineProps({
  items: { type: Array, default: () => ([]), required: true },
  indent: { type: Boolean, default: false },
})
const route = useRoute()

const itemColor = (item) => {
  if (route.path == item.to) {
    return 'bg-gray-500 text-white'
  } else {
    const classes = ['hover:bg-gray-100 hover:text-sky-600']
    if (item.show && item.children) {
      classes.push('bg-gray-100 text-sky-800')
      return classes.join(' ')
    } else {
      classes.push('bg-white')
      return classes.join(' ')
    }
  }
}
</script>

<template>
  <ul v-if="items.length">
    <li
      v-for="(item, i) of items"
      :key="i"
      class="border-b last:border-0"
    >
      <NuxtLink
        :to="item.children ? null : item.to"
        :class="[
          'flex items-center gap-2',
          'py-3 px-3 cursor-pointer',
          indent ? 'pl-6' : '',
          itemColor(item),
        ]"
        @click="item.show = !item.show"
      >
        <Icon
          v-if="item.icon"
          :icon="item.icon"
          :width="item.iconSize || 25"
        />
        <span>{{ item.label }}</span>

        <div class="flex-1"></div>

        <Icon
          v-if="item.children"
          icon="mdi:chevron-up"
          width="25"
          :class="[
            'transition duration-150 ease-in-out',
            item.show ? 'rotate-0' : 'rotate-180',
          ]"
        />
      </NuxtLink>

      <admin-ui-list
        v-if="item.children && item.children.length"
        indent
        :items="item.children"
        class="overflow-hidden "
        :class="item.show ? 'h-full border-t' : 'h-0'"
      />
    </li>
  </ul>
</template>