<script setup>
import admin_pages from '@/assets/json/admin.pages.json'
useHead({ bodyAttrs: { id: 'admin' } })

const route       = useRoute()
const { path }    = useRoute()
const { signOut } = useAuth()
const sSettings   = useState('settings')
const { site } = sSettings.value

const deepSearch = (obj, value, path = "") => {
  if (obj instanceof Array) {
    for (let i = 0; i < obj.length; i++) {
      const newPath = `${path}[${i}]`;
      const result  = deepSearch(obj[i], value, newPath);
      if (result) return result;
    }
  } else if (obj instanceof Object) {
    for (const prop in obj) {
      const newPath = path ? `${path}.${prop}` : prop;
      if (obj[prop] === value) return newPath;
      const result = deepSearch(obj[prop], value, newPath);
      if (result) return result;
    }
  }
  return null;
}

const pages = reactive(admin_pages)
const drawer = ref(false)

watch(() => route.path, () => {
  const objectPath = deepSearch(admin_pages, path)
  if (objectPath) {
    const [ firstIndex ] = objectPath ? objectPath.match(/\d+/g) : []
    if (firstIndex) pages[firstIndex].show = true
  }

  const main = document.querySelector('main')
  if (main) main.scroll({ top: 0 })
})

onMounted(() => {
  const objectPath = deepSearch(admin_pages, path)
  if (objectPath) {
    const [ firstIndex ] = objectPath ? objectPath.match(/\d+/g) : []
    if (firstIndex) pages[firstIndex].show = true
  }
})

const onLogout = () => {
  if (confirm('ログアウトしますか？')) signOut('/admin/login');
}
</script>

<template>
  <div class="admin-template flex flex-col bg-slate-100 w-screen h-screen overflow-hidden relative">
    <NuxtLoadingIndicator color="'#38bdf8'" />

    <!-- ヘッダー -->
    <section :class="[
      'flex justify-between',
      'h-10 md:h-12 w-full bg-sky-800',
    ]">
      <div class="flex items-center">
        <button
          class="block md:hidden ml-3 px-1 py-1 rounded-full bg-white text-sky-800 cursor-pointer"
          @click="drawer = !drawer"
        >
          <Icon
            :icon="drawer ? 'mdi:menu-open' : 'mdi:menu'"
            width="25"
            class=""
          />
        </button>

        <NuxtLink to="/" class="h-full text-xl font-bold hover:text-slate-300">
          <h1 class="px-6 text-white leading-10 md:leading-[48px]">{{ site.name }}</h1>
        </NuxtLink>

        <NuxtLink to="/" target="_blank" class="hidden sm:flex items-center pl-6 h-full font-bold text-sm hover:text-slate-300">
          <span class="text-white leading-10 md:leading-[48px]">「{{ site.name }}」サイトへ</span>
          <Icon icon="mdi:chevron-right" width="20" class="text-white" />
        </NuxtLink>
      </div>

      <div class="flex gap-2">
        <NuxtLink to="#" class="flex sm:hidden items-center h-full px-2 justify-self-end ">
          <div class="bg-white text-sky-800 p-[3px] rounded-full">
            <Icon icon="mdi:store" width="22" />
          </div>
        </NuxtLink>
      </div>
    </section>

    <div class="relative flex h-full overflow-x-hidden overflow-y-auto">
      <admin-ui-drawer v-model="drawer">
        <admin-ui-list :items="pages" class="border-b" />

        <div class="sticky bottom-0 w-full px-3 py-2 mt-auto bg-slate-200">
          <admin-ui-button
            block
            outlined
            @click="onLogout"
          >ログアウト</admin-ui-button>
        </div>
      </admin-ui-drawer>

      <main class="w-full overflow-x-hidden overflow-y-auto pb-14 relative">
        <admin-ui-toast show-close position="bottom-right" />
        <slot></slot>
      </main>
    </div>

    <admin-ui-loading />
  </div>
</template>

<style lang="scss" scoped>
.admin-template {
  & a {
    color: #0369a1;
    &:hover {
      text-decoration: underline;
    }
  }
}
</style>